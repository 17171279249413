@import "variables";

.btn-rounded.btn-primary, .btn-secondary.btn-rounded {
  transition: transform .2s;

  &:hover {
    background-color: var(--bs-secondary);
    border-color: var(--bs-secondary);
    transform: scale(1.1);
  }
}

.btn-secondary {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-disabled-color: var(--bs-white);
}

.form-label {
  margin-left: $input-border-radius;
}

.form-control, .form-select {
  transition: transform .2s;

  &:hover:not(:disabled) {
    border-color: var(--bs-success);
    transform: scale(1.001);
  }
}

next-password-input, .input-group {
  .input-group-text:not(:empty) {
    transition: transform .2s;
    border-top-width: $input-border-width;
    border-bottom-width: $input-border-width;
    border-left-width: $input-border-width;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    background-color: $light;
  }

  input.form-control:has(~ .input-group-text:empty) {
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
  }

  .btn-outline-primary {
    &:not(:hover) {
      background-color: var(--bs-white);
    }

    transition: transform .2s;
    border-top-width: $input-border-width;
    border-bottom-width: $input-border-width;
    border-right-width: $input-border-width;
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
  }

  input.form-control:hover:not(:disabled) + .btn-outline-primary, .input-group-text:has(+ input.form-control:hover:not(:disabled)) {
    border-color: var(--bs-success);
    transform: scale(1.01);
  }
}

.form-select-sm {
  border-radius: $input-border-radius !important;
}

ul.pagination {
  margin-bottom: 0;
}

@import "node_modules/@netserv/kalivah-angular-kit/assets/scss/variables";

$font-family-sans-serif: $font-family-base;
$body-color: $primary; // Text color
$body-bg-primary: $medium; // Other body background color used for linear gradient

$management-navbar-height: 70px;
$management-sidebar-width: 260px;
$management-sidebar-condensed-width: 70px;
$management-sidebar-color: #171129;
$management-footer-height: 60px;

$breadcrumb-divider-color: #fff;
$breadcrumb-active-color: #fff;
$breadcrumb-divider: quote(">");

$card-border-radius: 26px;

$input-border-color: $primary;
$input-border-width: 2px;
$input-border-radius: 15px;
$input-bg: #fff;
$input-placeholder-color: $light;
$input-focus-box-shadow: transparent;

$table-striped-bg: $light;
$table-color: #707070;
$table-striped-color: $table-color;

/* You can add global styles to this file, and also import other style files */

/* Add Avenir font */
//@import "assets/fonts/avenir/avenir";

/* Add Figtree font */
@import "@fontsource/figtree";

/* Override bootstrap variables. */
@import "assets/scss/variables";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Override bootstrap classes */
@import "assets/scss/bootstrap-override";

html, body {
  background: linear-gradient($medium, $success);
}

/** Custom buttons */
.btn-rounded {
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

/** Table **/
th {
  color: $secondary;
}
td {
  vertical-align: middle;
}

/** Sortable table icon */
th[ng-reflect-sortable] {
  cursor: pointer;

  &:after {
    opacity: 0;
    content: "";
    display: block;
    //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC) no-repeat;
    background: url('/assets/images/arrow-up.png') no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: right;
  }

  &:hover:not(.desc):not(.asc):after {
    opacity: 0.2;
  }

  &.desc:after, &.asc:after {
    opacity: 1;
  }

  &.desc:after {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}

/** Custom **/

input[type=checkbox] {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
